const $$ = (selector: string) =>
  Array.from(document.querySelectorAll<HTMLElement>(selector));
// const $ = (selector: string) => document.querySelector(selector);

export const initAdminHtmlHelpers = () => {
  $('[data-toggle-trigger]').off('click'); // remove any existing event listeners

  for (const el of $$('[data-toggle-trigger]')) {
    const id = el.dataset.toggleTrigger;
    if (id)
      el.addEventListener('click', () => {
        for (const target of $$(`[data-toggle-item="${id}"]`)) {
          target.classList.toggle('hidden');
        }
      });
  }
};

if (typeof window !== 'undefined') {
  // allow toggling the visibility of another element
  // e.g. <button data-toggle-trigger="foo"/> will toggle the visibility of <div data-toggle-item="foo"/>
  window.addEventListener('load', initAdminHtmlHelpers);
}

export {};
